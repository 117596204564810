<template lang="pug">
b-card
  validation-observer(ref="form", tag="form")
    b-form
      b-row
        // Control
        b-col(md="12")
          b-form-group(:label="$t('label.control')", :label-for="$t('label.control')", description="")
            validation-provider(:name="$t('label.control')", rules="required", v-slot="{ errors }")
              b-form-input(
                v-model="data.name",
                trim,
                autofocus,
                :placeholder="$t('placeholder.control')"
              )
              small(class="text-danger") {{ errors[0] }}

      b-row
        // Behaviours
        b-col(md="12")
          b-form-group(:label="$t('label.behaviours')", :label-for="$t('label.behaviours')")
            draggable(v-model="behaviours" tag="ul" class="draggable-list")
              li(v-for="(behaviour, idx) in behaviours", :key="idx", class="draggable-item")
                feather-icon(icon="MenuIcon", class="menu-icon")
                span.mr-1 {{ `${idx + 1}.` }}
                v-select(
                  v-model="behaviours[idx]"
                  class="behaviour-select"
                  label="title"
                  :options="behaviourOptions"
                  :reduce="(option) => option.value"
                  :placeholder="$t('placeholder.behaviourSelect')"
                  :append-to-body="true"
                )
                small(v-show="!behaviour", class="text-danger ml-1") {{ validationMessage }}
                b-button(variant="outline-danger", class="ml-2", @click="removeBehaviour(idx)")
                  feather-icon(icon="XIcon", class="mr-25")
                  span {{ $t('message.delete') }}

      b-row
        // Add behaviour button
        b-col(md="12")
          b-button(class="mt-1", variant="primary", @click="addBehaviour")
            feather-icon(icon="PlusIcon", class="mr-25")
            span {{ $t('message.Add_New_Behaviour') }}

      // Submit Button
      b-row
        b-col(cols="12", class="text-right")
          b-button(
            variant="primary",
            :disabled="isSubmitting",
            @click="validateAndSubmit"
          )
            | {{ $t('message.submit') }}

</template>

<script>
import { ref, onMounted, computed, watch } from '@vue/composition-api/dist/vue-composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from "@validations";
import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'
import vSelect from 'vue-select'
import realmConnection from '@/views/habit/realm'
import store from '@/store'
import draggable from 'vuedraggable'

export default {
  name: 'Form',

  components: {
    ValidationObserver,
    ValidationProvider,
    vSelect,
    draggable,
  },

  props: ['data', 'isSubmitting'],

  setup(props, { emit }) {
    const { showErrorMessage } = useNotifications()
    const { getItems, ObjectId } = realmConnection()
    const form = ref(null)
    const userData = store.state?.userStore?.userData
    const behaviours = ref([])
    const behaviourOptions = ref([])
    const validationMessage = computed(() => i18n.t('label.required'))
    const isSubmitted = ref(false)

    const addBehaviour = () => {
      behaviours.value.push('')
    }

    const removeBehaviour = (idx) => {
      behaviours.value.splice(idx, 1)
    }

    watch(props, val => {
      if (val.data?.behaviours && !isSubmitted.value) behaviours.value = val.data.behaviours
    })

    const validateForm = () => new Promise((resolve, reject) => {
      form.value.validate().then(success => {
        if (success) resolve(true)
        else reject()
      })
    })

    const validateAndSubmit = () => {
      // Check if there are missing or repeated behaviours
      const behaviourRegistry = {}

      for (const behaviour of behaviours.value) {
        if (!behaviour)  return showErrorMessage(i18n.t('message.requiredFieldsIncomplete'))
        if (behaviourRegistry[behaviour]) return showErrorMessage(i18n.t('message.behavioursRepeated'))
        behaviourRegistry[behaviour] = 1
      }

      validateForm()
        .then(() => {
          isSubmitted.value = true
          const payload = {
            name: props.data.name,
            behaviours: behaviours.value
          }
          emit('submit', payload)
        })
        .catch(() => showErrorMessage(i18n.t('message.requiredFieldsIncomplete')))
    }

    onMounted(async () => {
      try {
        const query = {
          client_id: ObjectId(userData?.client?.$oid),
          deleted: { $ne: true }
        }

        const items = await getItems({ collection: 'behaviour', query, options: { sort: { name: 1 } } })
        behaviourOptions.value = items?.map(e => ({ title: e.name?.replace(/<[^>]*>/g, ''), value: String(e._id) })) || []
      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.data_fetch_error'))
      }
    })

    return {
      required,
      form,
      validateAndSubmit,
      behaviourOptions,
      validationMessage,
      addBehaviour,
      removeBehaviour,
      behaviours,
    }
  },
}
</script>

<style scoped>
.draggable-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.draggable-item {
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.draggable-item:hover {
  background-color: #f9f9f9;
}

.menu-icon {
  margin-right: 14px;
  cursor: move;
}

.behaviour-select {
  flex-grow: 1;
}
</style>
